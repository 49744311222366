.how-works {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: rem(128);
  padding-bottom: rem(128);
  background: $clr-main;

  &__title {
    margin: auto;
    text-align: center;
    color: $white;

    &.heading_2 {
      max-width: rem(770);
      font-weight: bold;
      line-height: rem(50);
      margin-bottom: rem(16);
    }

    &.heading_3 {
      max-width: rem(656);
      font-size: rem(18);
      font-weight: normal;
      line-height: rem(22);
    }

    strong {
      @include gradient-color($clr-gradient);
    }
  }
}

.how-works {
  * + .swiperWorks-container {
    margin-top: rem(50);
  }
}

/*========================================================
                      Responsive
=========================================================*/

.how-works  {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    padding-top: rem(128);
    padding-bottom: rem(128);
  }

  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    padding-top: rem(80);
    padding-bottom: rem(80);

    &__title {
      &.heading_2 {
        font-size: rem(24);
        line-height: rem(36);
      }
    }
  }

  // ================
  // min-width: 768 and max-width: 1023px
  @include between(map-get($grid-breakpoints, md), (map-get($grid-breakpoints, lg) - 1px)) {
    .tablet-wrap {
      display: block;
    }

    * + .swiperWorks-container {
      margin-top: rem(32);
    }
  }

  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    padding-top: rem(40);
    padding-bottom: rem(40);

    &__title {

      &.heading_2 {
        padding: 0 8px;
      }

      &.heading_3 {
        padding: 0 8px;
        font-size: rem(14);
        line-height: rem(17);
      }
    }
    * + .swiperWorks-container {
      margin-top: rem(32);
    }
  }
}
