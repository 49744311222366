.swiperCatalog {
    &-container {
        position: relative;
        width: calc(100% - 80px);
        height: 100%;
        margin-left: auto;
        margin-right: auto;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: rem(280);
            background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.740331) 48.62%, rgba(255, 255, 255, 0) 100%);
            z-index: 2;
        }

        &:before {
            left: 0;
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &:after {
            right: 0;
        }
    }

    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transform: translate3d(0px, 0, 0);
        z-index: 1;

    }
}

.swiperCatalog {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: rem(280);
    height: auto;
    transform-style: preserve-3d;

    &:hover {
        .swiperCatalog__price {
            visibility: hidden;
            opacity: 0;
        }

        .swiperCatalog__btn {
            visibility: visible;
            opacity: 1;
        }
    }

    &__img-zone {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: rem(280);
        padding: rem(16);
        background: $white;
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(6, 26, 70, 0.08), 0px 0px 1px rgba(6, 26, 70, 0.08);

        img {}
    }

    &__title {
        margin-top: rem(16);
        text-align: center;
        font-size: rem(18);
        font-weight: 600;
        line-height: rem(27);
        min-height: rem(54);
    }

    &__price {
        margin-top: rem(8);
        font-size: rem(34);
        line-height: rem(50);
        @include gradient-color($clr-gradient);
    }

    &__btn-wraper {
        position: absolute;
        bottom: rem(6);
        left: rem(20);
        right: rem(20);
        width: calc(100% - 40px);
        transition: 0.3s ease all;
    }
    &__btn {
        visibility: hidden;
        opacity: 0;
        width: 100%;
    }
}

.swiperCatalog {
    &__nav-btn {
        cursor: pointer;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $white;
        width: rem(48);
        height: rem(48);
        background: $clr-gradient;
        top: calc(50%);
        transform: translateY(-50%);
        transition: 0.3s;
        z-index: 10;

        &:focus {
            outline: transparent
        }

        &:before {
            position: relative;
            font-size: 16px;
            color: currentColor;
            font-family: $fa;
        }

        &:hover {
            filter: brightness(110%);
        }

        &--next {
            right: rem(16);

            &:before {
                content: "\f054";
                left: 1px;
            }
        }

        &--prev {
            left: rem(16);

            &:before {
                content: "\f053";
                right: 1px;
            }
        }

        &.swiper-button-disabled {
            opacity: 0.15;
        }
    }

    &__scrollbar {
        margin-top: rem(64);
        height: rem(8);

        &:before {
            content: '';
            position: absolute;
            height: 2px;
            top: 50%;
            left: 0;
            right: 0;
            background: $clr-gradient;
            opacity: .2;
            transform: translateY(-50%);
        }

        .swiper-scrollbar-drag {
            height: rem(8);
            width: rem(100);
            position: relative;
            border-radius: rem(4);
            background: $clr-gradient;
        }
    }
}

.is-mobile {
    .swiperCatalog {
        &:hover {
            .swiperCatalog__price {
                visibility: initial;
                opacity: initial;
            }
            .swiperCatalog__btn{
                visibility: visible;
                opacity: 0;
            }
        }

        &__btn-wraper {
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 1;
        }
        &__btn {
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
}

/*========================================================
                      Responsive
=========================================================*/
.swiperCatalog {
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include below((map-get($grid-breakpoints, xl) - 1px)) {
        &-container {
            &:before,
            &:after {
                width: rem(208);
                background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.740331) 28.18%, rgba(255, 255, 255, 0) 100%);
            }
        }

        width: rem(208);

        &__img-zone {
            width: rem(208);
            height: rem(208);
        }

        &__scrollbar {
            margin-top: rem(40);
        }
    }
}
