.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  line-height: rem(19);
  font-size: rem(14);
  color: $clr-main-gray;

  &__link {
    cursor: pointer;
    display: inline-flex;
    text-decoration: none;
    color: $clr-main-gray;
    &:hover,
    &.is-active {
      color: #ff9b0b;
      // @include gradient-color($clr-gradient);
    }
  }

  &__item + &__item {
    margin-left: rem(32);
  }
}

/*========================================================
                      Responsive
=========================================================*/

.nav-list {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    &__item + &__item {
      margin-left: rem(16);
    }
  }
}