// Colors
$white: #ffffff;
$black: #000000;

$clr-main: #222848;
$clr-main-gray: #7A7E92;
$clr-light-gray: #A7A9B6;
$clr-border: #D5D7DD;
$clr-orange: #F28C24;

$clr-gradient: -webkit-linear-gradient(#FF9B0B, #F73F13);

$bg-main: $white;

$default: $clr-main;

//Backgrounds
$header-color: $default;
$header-bg: $bg-main;

$content-color: $default;
$content-bg: $bg-main;

$footer-color: $default;
$footer-bg: $bg-main;
