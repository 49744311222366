.checkbox {
  position: relative;
  display: inline-flex;
  &__input {
    display: none;
  }
  &__input + label {
    cursor: pointer;
    position: relative;
    margin: 0;
    padding-left: rem(32);
    color: $clr-light-gray;
    font-size: rem(14);
    font-weight: 400;
    line-height: rem(24);
    letter-spacing: normal;
    text-transform: none;
    transition: 0.3s ease all;
    z-index: 1;
  }

  &__input:checked
  + &__label
  ~ &__button {
    //color: $clr-main;
    background: $clr-gradient;
    &::after, &::before {
      opacity: 1;
      transition: height 0.28s ease;
    }
    &::after {
      height: 12px;
    }
    &::before {
      height: 20px;
      transition-delay: 0.28s;
    }

    i {
      border-color: transparent;
      > svg {
        opacity: 1;
      }
    }
  }

  &__button {
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    color: $clr-light-gray;
    left: 0;
    top: 0;
    width: rem(24);
    height: rem(24);
    z-index: 0;
    border: 1px solid currentColor;
    border: none;
    border-radius: 4px;
    transition: color 0.28s ease;
    will-change: color;
    background: $white;
    //&::before, &::after {
    //  position: absolute;
    //  height: 0;
    //  width: 2px;
    //  background-color: $white;
    //  display: block;
    //  transform-origin: left top;
    //  border-radius: 0.25rem;
    //  content: "";
    //  transition: opacity 0.28s ease, height 0s linear 0.28s;
    //  opacity: 0;
    //  will-change: opacity, height;
    //}
    //&::before {
    //  top: 18px;
    //  left: 10px;
    //  transform: rotate(-135deg);
    //  box-shadow: 0 0 0 0.0625rem $white;
    //}
    //&::after {
    //  top: 10px;
    //  left: 1px;
    //  transform: rotate(-45deg);
    //}

    i {
      position: absolute;
      left: 0;
      top: 0;
      width: rem(24);
      height: rem(24);
      border: 1px solid currentColor;
      border-radius: 4px;
      z-index: 0;
      > svg {
        opacity: 0;
        fill: $white;
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s ease all;
      }
    }
  }
}