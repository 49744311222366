.asside-nav {
  position: fixed;
  top: -1px;
  left: -100%;
  overflow-y: auto;
  min-width: rem(320);
  padding: rem(100) rem(40) rem(40) rem(20);
  background: rgb(229, 232, 237);
  height: 101%;
  transition: 0.4s ease all;
  box-shadow: 0px 8px 10px rgba(219, 105, 42, 0.3), 0px 0px 1px rgba(219, 105, 42, 0.28);
  z-index: 101;

  .hamburger {
    display: flex;
    position: absolute;
    right: rem(20);
    top: rem(20);
  }

  ul.nav-list {
    flex-direction: column;
    width: 100%;
    li {
      display: flex;
      align-items: center;
      height: rem(40);
    }

    li + li {
      margin-left: 0;
    }
  }
}

.asside-nav.is-open {
  left: -1px;
}

.asside-nav {
  //max-width: 575
  @include below(map_get($grid-breakpoints, sm) - 1px) {
    .nav-list__item {
      &.hidden-sm {
        display: none;
      }
    }
  }
}