button.btn.form__btn[disabled] {
  opacity: 0.6;
}

.form {
  &__input-group {
    display: flex;
  }

  &__input {
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: rem(18);
    line-height: 1;
    font-family: $font-default;
    font-weight: 500;
    padding: 0 rem(10);
    color: $clr-main;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #D5D7DD;
    border-radius: rem(25);
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    box-shadow: inset 0px 5px 4px rgba(34, 40, 72, 0.1)
  }

  &__input::placeholder {
    font-family: inherit;
    font-size: rem(14);
    line-height: 1;
    color: $clr-light-gray;
  }

}

.form {
  @include m('intro') {
    &.form {
      max-width: rem(570);
    }

    .form {
      &__input-group {
        position: relative;
        justify-content: flex-end;
      }
      &__input {
        position: absolute;
        left: 0;
        //width: calc(100% - 160px);
        height: rem(48);
        line-height: rem(46);
        padding-left: rem(32);
        padding-right: rem(280);
        &:-moz-placeholder {
          line-height: rem(46)
        }
        &::-moz-placeholder {
          line-height: rem(46)
        }
        &::-webkit-input-placeholder {
          line-height: rem(46)
        }
      }

      &__btn {
        width: rem(266);
        height: rem(48);
        box-shadow: none;
        &:hover,
        &:active {
          top: 0;
        }
      }
    }
  }

  @include m('catalog-send') {
    &.form {
      display: flex;
      flex-direction: column;
    }

    .form {
      &__title {
        font-size: rem(24);
        line-height: rem(36);

      }
      &__text {
        margin-top: rem(2);
        display: block;
        font-size: rem(14);
        line-height: rem(19);
        color: rgba($clr-main-gray, .6);
      }

      &__input {
        margin-top: rem(30);
        height: rem(60);
        border-radius: rem(30);
        padding-left: rem(32);
        padding-right: rem(20);
        &:-moz-placeholder { /* Firefox 18- */
          line-height: rem(60);
        }
        &::-moz-placeholder { /* Firefox 19+ */
          line-height: rem(60);
        }
        &::-webkit-input-placeholder {
          line-height: rem(28)
        }
      }

      &__btn {
        margin-top: rem(24);
        width: 100%;
        height: rem(55);
        padding: 0;
      }

      &__checkbox {
        margin-top: rem(26);
        margin-left: rem(32);
      }
    }
  }

  @include m('phone-send') {
    &.form {
      background: $white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 29.7404px 29.7404px rgba(6, 26, 70, 0.12), 0px 0px 1.14386px rgba(6, 26, 70, 0.1);
      border-radius: rem(12);
      padding-top: rem(96);
      padding-bottom: rem(96);
    }

    .form {
      > * {
        padding-left: rem(86);
        padding-right: rem(86);
      }

      &__title {
        text-align: center;
        font-size: rem(34);
        line-height: rem(44);
        font-weight: 700;
      }
      &__text {
        margin-top: rem(32);
        text-align: center;
        font-size: rem(18);
        line-height: rem(27);
        color: $clr-main-gray;
      }
      &__group {
        margin-top: rem(64);
      }
      &__input {
        height: rem(60);
        border-radius: rem(30);
        padding-left: rem(32);
        padding-right: rem(20);
        &:-moz-placeholder { /* Firefox 18- */
          line-height: rem(60);
        }
        &::-moz-placeholder { /* Firefox 19+ */
          line-height: rem(60);
        }
        &::-webkit-input-placeholder {
          line-height: rem(28)
        }
      }

      &__btn {
        margin-top: rem(24);
        width: 99.8%;
        height: rem(55);
        padding: 0;
      }
    }
  }
}

//validation
.form {
  .validate-wraper {
    position: relative;
    label.error {
      position: absolute;
      color: red;
      left: rem(20);
      font-size: rem(12);
      line-height: rem(24);
    }
  }
  &__input-group {
    label.error {
      color: red;
      position: absolute;
      top: 100%;
      left: rem(20);
      font-size: rem(12);
    }
  }
}

/*========================================================
                      Responsive
=========================================================*/

.form {
  // min-width: 1366px
  @include above(map_get($grid-breakpoints, xl)) {
    @include m('phone-send') {
      > * {
        padding-left: rem(85);
        padding-right: rem(85);
      }
      .form {
        &__group {
          padding-left: rem(104);
          padding-right: rem(104);
        }
      }
    }
  }
  // max-width: 1365px
  @include below(map-get($grid-breakpoints, xl) - 1px) {
    @include m('phone-send') {
      &.form {
        padding-top: rem(64);
        padding-bottom: rem(74);
      }
      > * {
        padding-left: rem(48);
        padding-right: rem(48);
      }
      .form {
        &__text {
          margin-top: rem(24);
        }
        &__group {
          margin-top: rem(32);
        }
      }
    }
  }
  // max-width: 1023px
  @include below(map-get($grid-breakpoints, lg) - 1px) {
    @include m('intro') {
      &.form {
        max-width: rem(448);
      }
      .form {
        &__input {
          padding-left: rem(20);
          padding-right: rem(110);
        }
        &__btn {
          width: rem(208);
        }
      }
    }

    @include m('catalog-send') {
      .form {
        &__title {
          max-width: rem(266);
        }
      }
    }
  }

  // max-width: 767px
  @include below(map-get($grid-breakpoints, md) - 1px) {
    @include m('phone-send') {
      .form {
        &__title {
          font-size: rem(24);
          line-height: rem(36);
        }
        &__text {
          margin-top: rem(16);
          font-size: rem(14);
          line-height: rem(19);
        }
        &__group {
          margin-top: rem(32);
        }
      }
    }

    @include m('catalog-send') {
      &.form {
        margin-top: rem(180);
        padding-top: rem(32);
        padding-bottom: rem(32);
        background: $white;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 8px 10px rgba(6, 26, 70, 0.1), 0px 0px 1px rgba(6, 26, 70, 0.08);
        border-radius: rem(8);
      }
      > * {
        padding-left: rem(32);
        padding-right: rem(32);
      }
      .form {
        &__title {
          font-size: rem(24);
          line-height: rem(36);
        }
        &__text {
          margin-top: rem(16);
          font-size: rem(14);
          line-height: rem(19);
          color: rgba($clr-main-gray, 0.6);
        }
        &__input {
          margin-top: rem(32);
        }
        &__checkbox {
          margin-left: rem(32);
          padding: 0;
        }
      }
    }
  }
  // max-width: 575px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    @include m('intro') {
      &.form {
        max-width: rem(448);
      }
      .form {
        &__input-group {
          flex-direction: column;
        }
        &__input {
          position: relative;
        }
        &__btn {
          margin-top: rem(8);
          width: 100%;
        }
      }
    }

    @include m('catalog-send') {
      &.form {
      }
      > * {
        padding-left: rem(16);
        padding-right: rem(16);
      }
      .form {
        &__title {
          max-width: 100%;
          font-size: rem(18);
          line-height: rem(27);
        }
        &__checkbox {
          margin-left: rem(32);
        }
      }
    }

    @include m('phone-send') {
      &.form {
        padding-top: rem(32);
        padding-bottom: rem(32);
      }
      > * {
        padding-left: rem(16);
        padding-right: rem(16);
      }
    }
  }
}
