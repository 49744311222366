.list-buy-steps {
    @include list-reset();
    position: relative;
    margin-top: rem(14);
    z-index: 1;

    &__item {
        position: relative;
        margin: rem(40) 0;
        top: rem(50);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        opacity: 0;
        // &:nth-child(even) {
        //     margin-top: rem(165); // 165 + 250 = 415
        // }
    }

    &__title {
        margin: 0;
        margin-top: rem(24);
        line-height: rem(36);
    }

    &__text {
        margin: 0;
        margin-top: rem(8);
        font-size: rem(14);
        line-height: rem(19);
        max-width: rem(280);
    }
}

.list-buy-steps {
    .icon {
        fill: $white;
        width: rem(50);
        height: 100%;
    }
}


.list-buy-steps-wrap {
    position: relative;
}

#svg_line {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    path {
        opacity: 0;
    }
}

#svg_line_02 {
    transform: scale(1, -1)
}



.svg_bg-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    path {
        opacity: 0;
    }
}


/*========================================================
                      Responsive
=========================================================*/

@include above(map-get($grid-breakpoints, lg)) {

    .list-buy-steps {
        &__item {
            &:nth-child(even) {
                margin-top: rem(130); // 165 + 250 = 415
            }
        }
    }


    #svg_line {
        top: rem(80);
        width: rem(674);
        height: rem(124);
    }
    #svg_line_01,
    #svg_line_02 {
        width: rem(336);
        height: rem(124);
    }

    .svg_bg-icon {
        top: rem(50);
        max-width: rem(500);
        max-height: rem(214);
    }
}

@include above(map-get($grid-breakpoints, xl)) {
    .list-buy-steps {
        &__item {
            &:nth-child(even) {
                margin-top: rem(165); // 165 + 250 = 415
            }
        }
    }

    #svg_line {
        top: rem(100);
        width: rem(808);
        height: rem(124);
    }
    #svg_line_01,
    #svg_line_02 {
        width: rem(408);
        height: rem(128);
    }

    .svg_bg-icon {
        top: rem(50);
        max-width: rem(633);
        max-height: rem(238);
    }
}


@include below(map-get($grid-breakpoints, lg) - 1px) {
    .svg_bg-icon {
        top: rem(30);
        max-width: rem(447);
        max-height: rem(191);
    }
}
