.buy-now {
  padding-top: rem(160);
  padding-bottom: rem(80);

  &__faq {
    padding-top: rem(160);
  }
  &__title {
    margin: auto;
    text-align: center;

    &.heading_1 {
      line-height: rem(72);
    }

    &.heading_2 {
      line-height: rem(50);
    }
  }

  &__slogan {
    margin: auto;
    margin-top: rem(8);
    text-align: center;
    font-size: rem(18);
    max-width: rem(650);
  }

  &__accordion {
    margin-top: rem(64);
  }
}

/*========================================================
                      Responsive
=========================================================*/

.buy-now {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {

  }

  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    padding-top: rem(128);
    padding-bottom: rem(64);
    &__title {
      &.heading_1 {
        font-size: rem(34);
        line-height: rem(51);
      }
    }
    &__faq {
      padding-top: rem(128);
    }
  }
  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    padding-top: rem(80);
    padding-bottom: rem(40);
    &__title {
      &.heading_2 {
        font-size: rem(24);
        line-height: rem(36);
      }
    }
    &__slogan {
      font-size: rem(16);
    }
    &__accordion {
      margin-top: rem(32);
    }
    &__faq {
      padding-top: rem(80);
    }
  }

  // max-width: 575px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    order: 1;
    &__faq {
      display: none;
    }
  }
}