.accordion {
    &__item {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background: lighten(desaturate(adjust-hue($clr-main-gray, -230), 9.92), 40.78);
        }
    }

    &__head {
        position: relative;
        cursor: pointer;
        text-transform: none;
        padding: rem(15) rem(30) rem(15) rem(5);
        font-size: rem(24);
        font-weight: 500;
        color: $clr-main;

        &-left {
            padding-left: rem(30);
            padding-right: rem(5);
        }

    }

    &__cnt {
        display: none;
        padding-left: rem(60);
        padding-right: rem(60);
        padding-top: rem(12);
        padding-bottom: rem(48);
        font-size: rem(14);
        line-height: rem(21);
        color: rgba($clr-main, 0.7);
    }

    &__arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: rem(14);
        height: rem(14);
        font-size: 14px;
        line-height: 1;
        background: transparent;

        // transform: translateY(-50%);
        &:before {
            position: absolute;
            content: '\f067';
            top: 50%;
            left: 50%;
            transform: translateX(-50%)translateY(-50%);
            font-family: $fa;
            @include gradient-color($clr-gradient);
        }
    }

    &__arrow {
        &-left {
            left: 5px;
        }

        &-right {
            right: 5px;
        }
    }
}

// if accordion item element is open
.accordion__item_opened {
    .accordion__arrow {
        &:before {
            content: '\f068';
        }
    }
}

// max-width: 767px
@include below((map-get($grid-breakpoints, md) - 1px)) {
    .accordion {
        &__head {
            font-size: rem(18px);
            line-height: 1.2;
        }
    }
}
