.location {
    padding-top: rem(80);
    padding-bottom: rem(160);

    &__title {
        text-align: center;
        margin: auto;
        line-height: rem(50);
    }

    &__slogan {
        max-width: rem(680);
        text-align: center;
        margin-top: rem(8);
        margin-left: auto;
        margin-right: auto;
        font-size: rem(18);
        line-height: rem(22);
    }

    &__map {
        margin-top: rem(60);
        position: relative;
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__svg {
        position: relative;
        width: rem(862);
        height: rem(546);
        transition: 0.4s all;

        [data-state] {
            position: absolute;
            display: inline-flex;
            align-items: flex-end;
            span{
                margin: 0 rem(8);
                font-size: rem(18);
                line-height: rem(27);
            }
        }
        [data-reverse] {
            img {
                order: 1;
            }
        }
        [data-state="WA"] {
            top: rem(38);
            left: rem(90);
        }
        [data-state="CA"] {
            top: rem(180);
            left: rem(30);
        }
        [data-state="UT"] {
            top: rem(220);
            left: rem(185);
        }
        [data-state="NC"] {
            top: rem(280);
            right: rem(120);
        }
        [data-state="SC"] {
            top: rem(320);
            right: rem(150);
        }
    }
    
    &__navigate {
        position: absolute;
        top: rem(50);
        width: rem(384);
        height: rem(363);
        z-index: 2;
    }

    #map {
        position: absolute;
        top: 0;
        width: 100%;
        height: 550px;
        z-index: -1;
    }
}

// _qMapsMarkerLabelPositionFix css
.location #map .gm-style [style] * { 
    animation: nodeInserted  0.001s;
}

.location #map .customMarkerLabel {
    height: initial!important;
    margin: calc(-27px/2) 0 0 20px!important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

@keyframes nodeInserted { 
    from { opacity: 0.99; }
    to { opacity: 1;}
}

/*========================================================
                      Responsive
=========================================================*/

.location {
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
        &__navigate {
            top: rem(30);
            width: rem(288);
            height: rem(363);
        }
        &__svg {
            width: rem(660);
            height: rem(417);
            [data-state] {
                span{
                    margin: 0 rem(6);
                    font-size: rem(14);
                    line-height: rem(21);
                }
            }
            [data-state="WA"] {
                top: rem(20);
                left: rem(60);
            }
            [data-state="CA"] {
                top: rem(190);
                left: rem(40);
            }
            [data-state="UT"] {
                top: rem(155);
                left: rem(140);
            }
            [data-state="NC"] {
                top: rem(210);
                right: rem(140);
            }
            [data-state="SC"] {
                top: rem(245);
                right: rem(110);
            }
        }
        #map {
            height: rem(420);
        }
    }

    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        padding-top: rem(64);
        padding-bottom: rem(128);
        &__navigate {
            top: rem(-30);
            width: rem(296);
            height: rem(363);
        }
        &__svg {
            width: rem(483);
            height: rem(305);
            [data-state] {
                img {
                    width: rem(18);
                    height: rem(22);
                }
                span{
                    margin: 0 rem(8);
                    font-size: rem(14);
                    line-height: rem(22);
                }
            }
            [data-state="WA"] {
                top: rem(10);
                left: rem(50);
            }
            [data-state="CA"] {
                top: rem(150);
                left: rem(40);
            }
            [data-state="UT"] {
                top: rem(110);
                left: rem(100);
            }
            [data-state="NC"] {
                top: rem(155);
                right: rem(100);
            }
            [data-state="SC"] {
                top: rem(180);
                right: rem(80);
            }
        }
        #map {
            height: rem(310);
        }
    }

    // max-width: 767px
    @include below((map-get($grid-breakpoints, md) - 1px)) {
        padding-top: rem(40);
        padding-bottom: rem(80);
        &__map {
            margin-top: rem(48);
        }
        &__svg {
            width: 100%;
            height: auto;
        }
        &__navigate {
            position: relative;
            margin: rem(-80) auto auto;
            top: 0 !important;
            left: initial !important;
            right: initial !important;
            width: 100%;
            max-width: rem(540);
            padding: rem(12);
        }
        .stock-location {
            font-size: 1.3rem;
            line-height: normal;
        }
        .map-info__layyer {
            padding-top: 2.25rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

     //max-width: 575px
    @include below((map-get($grid-breakpoints, sm) - 1px)) {
    }
}