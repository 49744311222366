//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1366px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 768px, // 100%
        lg: 960px, // 928 + 32px 
        xl: 1248px // 1216 + qutter (32px)
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 32px;

// END of Grid Options
@import "~bootstrap-4-grid/scss/grid";
@import "~swiper/dist/css/swiper.css";
@import "~font-awesome/scss/font-awesome";
@import '~aos/src/sass/aos';
@import '~sweetalert2/src/sweetalert2';
