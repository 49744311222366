//@import "details/reasons-list";

.reasons {
  display: flex;
  align-items: center;
  padding-top: rem(160);
  padding-bottom: rem(80);

  &__title {
    margin: auto;
    text-align: center;
    line-height: rem(50);
    margin-bottom: rem(40);

    span {
      @include gradient-color($clr-gradient);
    }
  }
  &__text {
    display: none;
    text-align: center;
    font-size: rem(14);
    line-height: rem(19);
    color: $clr-main-gray;
  }
}

/*========================================================
                      Responsive
=========================================================*/

.reasons  {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    padding-top: rem(160);
    padding-bottom: rem(80);
  }
  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    padding-top: rem(128);
    padding-bottom: rem(64);
  }
  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    padding-top: rem(80);
    padding-bottom: rem(10);
    &__title {
      font-size: rem(24);
      line-height: rem(36);
    }
  }
  // max-width: 575px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    &__title {
      margin-bottom: rem(8);
    }
    &__text {
      display: block;
    }
  }
}