.about {
  display: flex;
  align-items: center;
  padding-top: rem(160);
  padding-bottom: rem(160);

  &__container {}


  &__title {
    margin: auto;
    text-align: center;
    max-width: rem(835);
    line-height: rem(51);
    font-weight: 400;
    margin-bottom: rem(56);
  }
}

.about-description {
  position: relative;
  margin-top: rem(40);
  padding-top: rem(64);
  padding-bottom: rem(64);
  padding-left: rem(32);
  padding-right: rem(30);
  border: 0.25px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 10px rgba(6, 26, 70, 0.1), 0px 0px 1px rgba(6, 26, 70, 0.08);
  border-radius: rem(8);

  &__decor {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: rem(-20);
    left: rem(32);
    width: rem(40);
    height: rem(40);
    background: $clr-gradient;
    border-radius: rem(3);
  }

  &__icon {
    width: rem(21);
    height: rem(24);
    fill: $clr-main;
  }

  &__title {
    font-weight: 700;
    font-size: rem(24);
    line-height: rem(21);
  }

  &__text {
    margin-top: rem(15);
    line-height: rem(19);
    color: $clr-main-gray;
  }
}

/*========================================================
                      Responsive
=========================================================*/

.about {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {

  }

  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    padding-top: rem(128);
    padding-bottom: rem(128);

    &__title {
      font-size: rem(24);
      line-height: rem(36);
      max-width: rem(586);
    }
  }
  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    padding-top: rem(80);
    padding-bottom: rem(80);
    &__svg {
      display: none;
    }
  }
}