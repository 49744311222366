.swiperBuyNow {
    &-container {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: rem(280);
            background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.740331) 48.62%, rgba(255, 255, 255, 0) 100%);
            z-index: 2;
        }
        &:before {
            display: none;
            left: 0;
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        &:after {
            right: 0;
        }
    }

    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transform: translate3d(0px, 0, 0);
        z-index: 1;

    }
}

.swiperBuyNow {
    margin-top: rem(70);
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    transform-style: preserve-3d;
    width: rem(250);
    height: rem(166);
    &__img {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(250);
        height: rem(166);
        img{
            object-fit: cover;
            width: auto;
            height: 100%;
        }
    }
}

/*========================================================
                      Responsive
=========================================================*/

.swiperBuyNow {
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
        margin-top: rem(40);
        width: rem(130);
        height: rem(86);
        &__img {
            width: rem(130);
            height: rem(86);
        }
    }
    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        margin-top: rem(32);
        width: rem(92);
        height: rem(62);

        &-container {
            &:before,
            &:after {
                width: rem(80);
            }
        }
        &__img {
            width: rem(92);
            height: rem(62);
        }
    }
    // max-width: 576
    @include below((map-get($grid-breakpoints, sm) - 1px)) {
        &-container {
            &:before{
                display: none;
            }
        }
    }
}
