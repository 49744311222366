.reviews {
    background: url('/images/content/backgrounds/bg-reviews.png') repeat center center / cover;
    padding-top: rem(128);
    padding-bottom: rem(128);
    color: $white;

    &__title {
        text-align: center;
        margin: auto;
        line-height: rem(50);
        span {
            @include gradient-color($clr-gradient);
        }
    }

    &__slogan {
        text-align: center;
        margin-top: rem(16);
        margin-left: auto;
        margin-right: auto;
        font-size: rem(18);
        line-height: rem(22);
    }
}

/*========================================================
                      Responsive
=========================================================*/

.reviews {
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {

    }

    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        padding-top: rem(80);
        padding-bottom: rem(80);

        &__title {
            font-size: rem(24);
            line-height: rem(36);
        }
        &__slogan {
            max-width: rem(420);
        }
    }
    // max-width: 767px
    @include below((map-get($grid-breakpoints, md) - 1px)) {
        padding-top: rem(40);
        padding-bottom: rem(40);
    }

    // max-width: 575px
    @include below((map-get($grid-breakpoints, sm) - 1px)) {
        order: 1;
    }
}