.categories-card {
  padding: rem(31) rem(20) rem(20) rem(20);
  width: 100%;
  height: rem(260);
  background: $white;
  border-radius: rem(4);
  margin-bottom: rem(40);
  transition: .3s ease all;
  &:hover {
    box-shadow: 0px 3.11111px 4.14815px rgba(6, 26, 70, 0.1), 0px 0px 1.03704px rgba(6, 26, 70, 0.08);

    .categories-card__title {
      visibility: hidden;
      opacity: 0;
    }
    .categories-card__btn {
      visibility: visible;
      opacity: 1;
    }
  }

  &__img-zone {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: auto;
    padding: 0 rem(11);
    height: rem(145);
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__title {
    text-align: center;
    margin-top: rem(31);
    font-size: rem(18);
    line-height: rem(27);
    transition: 0.3s ease all;
  }
  &__btn-wrap {
    position: relative;
    top: rem(-37);
  }
  &__btn {
    visibility: hidden;
    opacity: 0;
    width: 100%;
  }
}

.is-mobile {
  .categories-card {
    &:hover {
      .categories-card__title {
        visibility: initial;
        opacity: initial;
      }
      .categories-card__btn {
        visibility: visible;
        opacity: 0;
      }
    }

    &__btn-wrap {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
    }
    &__btn {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

/*========================================================
                      Responsive
=========================================================*/
.categories-card {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    padding: rem(23) rem(20) rem(10) rem(20);
    height: rem(192);

    &__img-zone {
      padding: 0 rem(3);
      height: rem(108);
    }
    &__title {
      line-height: rem(19);
      font-size: rem(14);
    }
    &__btn {
      height: rem(37);
    }
  }
  // ================
  // max-width: 575px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    margin: rem(8) 0;
    padding: rem(16) rem(16) rem(9);
    height: auto;
    &:hover {
      .categories-card__title {
        visibility: initial;
        opacity: initial;
      }
      .categories-card__btn {
        visibility: visible;
        opacity: 0;
      }
    }
    &__img-zone {
      padding: 0;
      height: rem(75);
      justify-content: center;
      img {
        max-height: rem(75);
      }
    }
    &__title {
      margin-top: rem(25);
    }
    &__btn-wrap {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
    }
    &__btn {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}