.round {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: rem(120);
  height: rem(120);
  border-radius: 50%;
  background: $clr-gradient;
  box-shadow: 0px 8px 10px rgba(219, 105, 42, 0.3), 0px 0px 1px rgba(219, 105, 42, 0.28);

  &__index {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    background: $clr-gradient;
    box-shadow: 0px 8px 10px rgba(219, 105, 42, 0.3), 0px 0px 1px rgba(219, 105, 42, 0.28);
    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      content: '';
      width: rem(30);
      height: rem(30);
      border-radius: 50%;
      background: $white;
    }
    &:after{
      counter-increment: li;
      content: counter(li);
      line-height: 1;
      font-size: rem(14);
      font-weight: bold;
      @include gradient-color($clr-gradient);
    }
  }
}
