.how-buy {
    padding-top: rem(80);
    padding-bottom: rem(160);

    &__container {

    }
    &__title {
        margin: auto;
        text-align: center;
        font-size: rem(48);
        line-height: rem(72);
    }
    &__slogan {
        text-align: center;
        margin-top: rem(16);
        font-size: rem(18);
        line-height: rem(27);
        font-weight: initial;
    }
}

/*========================================================
                      Responsive
=========================================================*/

.how-buy {
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {

    }
    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        padding-top: rem(64);
        padding-bottom: rem(64);
        &__title {
            font-size: rem(34);
            line-height: rem(51);
        }
    }
    // max-width: 767px
    @include below((map-get($grid-breakpoints, md) - 1px)) {
        padding-top: rem(40);
        padding-bottom: rem(64);
        background: url("/images/content/backgrounds/how-buy_767.svg") repeat-y center;
    }
}