.catalog-parts {
  padding-top: rem(80);
  padding-bottom: rem(80);
  &__title {
    margin: auto;
    text-align: center;
    font-size: rem(48);
    line-height: rem(72);
  }
  &__slogan {
    margin-top: rem(16);
    text-align: center;
    display: block;
    font-size: rem(18);
    line-height: rem(27);
    font-weight: 400;
  }

  &__partner-list {
    @include c('partner-list') {
      &.partner-list {
        margin-top: rem(32);
        justify-content: center;
      }
      .partner-list {
        &__item {
          margin: rem(15) rem(22);
        }
        &__link {
          width: rem(140);
          height: rem(60);
        }
      }
    }
  }
  &__slider {
    position: relative;
    margin-top: rem(160);
  }

  &__catalog {
    margin-top: rem(64);
  }
}

.catalog-parts {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: rem(-100);
    top: 0;
    width: rem(480);
    height: rem(320);
    background: url(../images/content/backgrounds/Hoses.png) no-repeat center center / cover;
    filter: blur(3px);
    transform: rotate(30deg);
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    right: rem(-200);
    top: rem(300);
    width: rem(480);
    height: rem(320);
    background: url(../images/content/backgrounds/Tires.png) no-repeat center center / cover;
    filter: blur(3px);
    transform: rotate(30deg);
    z-index: 1;
  }
}

.filter-result {
  &__item {
    display: block;
    @include m('has-btn') {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(60);
    }
  }
}

/*========================================================
                      Responsive
=========================================================*/

.catalog-parts {
  @include below(1920px) {
    &:before,
    &:after {
      display: none;
    }
  }
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    padding-top: rem(80);
    padding-bottom: rem(80);
    &__slider {
      margin-top: rem(160);
    }
  }

  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    padding-top: rem(64);
    padding-bottom: rem(64);
    &__catalog{
      margin-top: rem(32);
    }

    &__title {
      font-size: rem(34);
      line-height: rem(51);
    }
    &__slogan {
    }

    &__partner-list {
      @include c('partner-list') {
        .partner-list {
          &__item {
            margin: rem(0) rem(16);
          }
        }
      }
    }
    &__slider {
      margin-top: rem(128);
    }
  }
  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    padding-top: rem(40);
    padding-bottom: rem(40);

    &__title {
      font-size: rem(34);
      line-height: rem(51);
    }
    &__slogan {
    }

    &__partner-list {
      @include c('partner-list') {
        .partner-list {
          &__item {
            margin: rem(0) rem(8);
          }
        }
      }
    }
    &__slider {
      display: none;
    }
  }
  // max-width: 575px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    &__title {
      font-size: rem(24);
    }
    &__partner-list {
      @include c('partner-list') {
        .partner-list {
          &__item {
            margin: rem(0) rem(2);
          }
        }
      }
    }
  }
}
