.list-reasons {
  @include list-reset();
  counter-reset: li;
  &__item{
    margin: rem(40) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__title{
    margin: 0;
    margin-top: rem(24);
    line-height: rem(36);
  }
  &__text{
    margin: 0;
    font-size: rem(14);
    line-height: rem(19);
    margin-top: rem(8);
    max-width: rem(320);
  }
}

.list-reasons {
  .icon{
    fill: $white;
    width: rem(50);
    height: 100%;
  }
}

/*========================================================
                      Responsive
=========================================================*/
.list-reasons {
  // max-width: 1023px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    &__text{
      display: none;
    }
  }
  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    &__item{
      margin: rem(30) 0;
    }
  }
  // max-width: 575px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    &__title{
      margin-right: rem(16);
      font-size: rem(18);
      line-height: rem(27);
    }
  }
}