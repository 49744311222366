.footer {
  position: relative;
  padding-bottom: rem(32);

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav-container {
    display: inline-flex;
    align-items: center;

    > * + * {
      margin-left: rem(32);
    }
  }

  &__icon {
    &.icon_envelope {
      fill: $clr-main-gray;
      &:hover {
        fill: url('#envelopeGradient');
      }
    }
  }
}

.footer {
  .btn-phone {
    background: $clr-gradient;
    color: $white;
    &__text {
      background: initial;
      -webkit-text-fill-color: initial
    }
  }
}

/*========================================================
                      Responsive
=========================================================*/

.footer {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    &__nav-container {
      > * + * {
        margin-left: rem(16);
      }
    }
  }

  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    .brand {
      width: rem(91);
      height: rem(40);
    }

    .nav-list {
      display: none;
    }
    &__envelope {
      display: none;
    }
  }
  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
  }
  // max-width: 575px
  @include below((map-get($grid-breakpoints, sm) - 1px)) {
    padding-bottom: rem(16);
    order: 1;
    .brand {
      width: rem(73);
      height: rem(32);
    }
    .btn-phone,
    .btn-phone a {
      font-size: rem(14);
      line-height: rem(32);
      width: rem(128);
      height: rem(32);
    }

  }
}
