.hamburger {
  display: none;
  position: relative;
  height: rem(30);
  width: rem(30);
  padding: 0 rem(20);
  &:hover {
    cursor: pointer;
  }
  &__icon {
    position: absolute;
    height: rem(2);
    width: rem(18);
    top: 50%;
    left: 50%;
    border-radius: 50px;
    background-color: $clr-main;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: all ease 0.5s;
    &::before, &::after {
      content: "";
      position: absolute;
      height: inherit;
      border-radius: inherit;
      background-color: inherit;
      margin: auto;
      width: 100%;
      transition: all ease 0.5s;
    }
    &::before {
      top: rem(-5);
      left: 0;
      transform-origin: left;
    }
    &::after {
      bottom: rem(-5);
      right: 0;
      transform-origin: right;
    }
  }
}

.hamburger.is-open {
  @include c('is-open') {
    .hamburger {
      &__icon {
        background-color: $clr-main-gray;
        transform: translate(-50%, -50%) rotate(135deg);
        &::before {
          top: 0;
          width: 50%;
          transform: translateX(100%) rotate(-90deg);
        }
        &::after {
          bottom: 0;
          width: 50%;
          transform: translateX(-100%) rotate(-90deg);
        }
      }
    }
  }
}