.svg-parallax {
    position: relative;
    padding-top: rem(80);
    padding-bottom: rem(80);

    &__container {
        position: relative;
        z-index: 1;
    }

    &__clip {
        position: absolute;
        left: 0;
        right: 0;
        bottom: rem(130);
    }
    &__bg {
        svg {
            min-height: 100%;
            max-height: rem(517);
            display: block;
            fill: $white;
        }
        background: url("/images/content/parallax/bg-1.jpg") no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
    }
}


.svg-parallax {
    @include m('02') {

        .svg-parallax {
            &__img {
                position: absolute;
                animation: timing 5s infinite;
                z-index: 1;
            }
        }
    }
}

@keyframes timing {
    0% {
        transform: translate3d(0, -3%, 0);
    }

    50% {
        transform: translate3d(0, 3%, 0);
    }

    100% {
        transform: translate3d(0, -3%, 0);
    }
}

/*========================================================
                      Responsive
=========================================================*/
.svg-parallax {
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
    }

    // max-width: 1365px
    @include below((map-get($grid-breakpoints, xl) - 1px)) {
        &__clip {
            min-height: rem(422);
            bottom: rem(80);
        }
        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        padding-top: rem(64);
        padding-bottom: rem(64);
        &__img {
            display: none;
        }
        &__clip {
            bottom: rem(80);
            min-height: rem(390);
        }
    }
    // max-width: 767px
    @include below((map-get($grid-breakpoints, md) - 1px)) {
        padding-top: rem(40);
        padding-bottom: rem(40);
        &__clip {
            bottom: rem(48);
            min-height: rem(320);
        }
    }

    // max-width: 575px
    @include below((map-get($grid-breakpoints, sm) - 1px)) {
        order: 1;
        @include m('02') {
            &.svg-parallax {
                padding-bottom: rem(80);
            }
        }
    }
}
