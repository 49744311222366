*,
*::before,
*::after {
  box-sizing: border-box;
}

.development-mode {
  background: rgba(0, 0, 0, 0.03)
}

div {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

canvas {
  width: 100%;
}

/*========================================================
                      Main Styles
=========================================================*/
/*
* @section      Main Styles
* @description  This section holds template default elements style declarations
*/
.page {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

html {
  font-size: $default-font-size;
}

body {
  //overflow: hidden;
  font-family: $font-default;
  font-weight: $default-font-weight;
  font-size: $default-font-size;
  line-height: $default-line-height;
  -webkit-text-size-adjust: none;
  color: $content-color;
  background: $content-bg;
}

/*========================================================
                      Typography
=========================================================*/
h1,
h2,
h3,
.heading_1,
.heading_2,
.heading_3 {
  font-weight: 700;
}

h1,
.heading_1 {
  font-size: rem(48);
  line-height: 1;
}

h2,
.heading_2 {
  font-size: rem(34);
  line-height: 1;
}

h3,
.heading_3 {
  font-size: rem(24);
  line-height: 1;
}

p {
  font-weight: 400;
  font-size: rem(18);
}

/*
* @subsection   Typography
* @description  Describes default font settings for basic html elements and
*               css classes
*/
a,
a[href^="tel:"],
a[href^="callto:"],
a[href^="mailto:"] {
  font: inherit;
  color: inherit;
  text-decoration: none;
  transition: 0.3s;

  &:before {
    transition: 0.3s;
  }

  &:hover {
    &:before {
    }
  }

  &:focus {
    outline: none;
  }
}

a:active,
a:hover {
  outline: 0;
}

/*=======================================================
                       Main Layout
=========================================================*/

/*
* @subsection   Backgrounds
* @description  Backgrounds custom styles
*/

/*
* @subsection   Colors
* @description  Colors custom styles
*/

/*
* @subsection   Well
* @description  Describes section inner indents
*/

/*
* @subsection   Offsets
* @description
*/

/**
 * Remove border when inside `a` element in IE 8/9/10.
 */

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
  overflow: hidden;
}

/* ========================================================
                     Components
=========================================================*/
.loader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;
  min-height: calc(100vh - 230px);
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  &__overlay {
    position: absolute;
    background: #E5E8ED;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
  }
  &__svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: rem(274);
    height: rem(120);
    z-index: 1000;
    transform: translate3d(-50%, -50%, 0);
  }
  .loader {
    transform-origin: 38% center;
    transform: scaleX(0);
    animation: loader 1s ease-out;
  }
}

@keyframes loader {
  to {
    transform: scaleX(1);
  }
}

.loading {
  position: fixed;
  background: rgba($clr-main, 0.9);
  width: 100%;
  height: 100%;
  display: none;
  z-index: 10000;
  &__svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.hidden {
  display: none;
}

/* ========================================================
                     HEADER  Section
=========================================================*/

/*
* @section      Page Header
* @description  This section holds specific style redeclarations for some
*               of common elements in page header
*/

header,
.page-header {
  // background: $header-bg;
  // color: $header-color;
}

/* ========================================================
					 MAIN  Section
=========================================================*/

/*
* @section      Page Content
* @description  This section holds specific style redeclarations for some
*               of common elements in page content
*/

main,
.page-content {
}

.swal2-popup.swal2-toast {
  &.alert {
    position: relative;
    padding: rem(8) rem(15);
    border: 1px solid;
    border-radius: rem(4);
    font-size: rem(14);
    align-items: center;
    > * {
      line-height: rem(20);
    }

    @include m('success') {
      &.alert {
        flex-direction: column;
        align-items: flex-start;
        color: #28623c;
        background-color: #dbf2e3;
        border-color: #cdedd8;
      }
      .swal2-title {
        color: #28623c !important;
        margin: 0 0 rem(5) 0;
      }
    }
    @include m('danger') {
      &.alert {
        flex-direction: column;
        align-items: flex-start;
        color: #813838;
        background-color: #fee2e1;
        border-color: #fdd6d6;
      }
      .swal2-title {
        color: #813838 !important;
        margin: 0 0 rem(5) 0;
      }
    }
  }
}

/* ========================================================
                     FOOTER  Section
=========================================================*/

/*
* @section      Page Footer
* @description  This section holds specific style redeclarations for some
*               of common elements in page footer
*/

footer,
.page-footer {
  // background: $footer-bg;
  // color: $footer-color;
}

/* ========================================================
                     @MEDIA GRID
=========================================================*/

@media screen and (max-width: (map-get($grid-breakpoints, lg) - 1px)) {
  .container, .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
  .row {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }
}

@media screen and (max-width: (map-get($grid-breakpoints, sm) - 1px)) {
  .container, .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  .row {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }
}