body.modal-is-open {
  overflow: hidden !important;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  padding: rem(32);
  background: rgba($clr-main, 0.6);
  z-index: 101;
  &.is-open {
    overflow-y: auto;
  }
}
.modal {
  position: relative;
  max-width: rem(590);
  height: auto;
  margin: auto;
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(10);
    right: rem(10);
    width: rem(30);
    height: rem(30);
    background: url("/images/content/modal/close.svg") no-repeat center center;
    z-index: 1;
  }
}