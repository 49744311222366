.map-info {
	overflow: hidden;
	position: relative;
	display: flex;
	width: 100%;
	min-height: 330px;
	background: $white;
	border: 0.25px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 26px 26px rgba(6, 26, 70, 0.12), 0 0 1px rgba(6, 26, 70, 0.1);
	border-radius: 10px;
	&__layyer {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: baseline;
		flex-direction: column;
		background: $white;
		padding: rem(32);
		transition: 0s all;
		&.active {
			left: 0!important;
			opacity: 1!important;
			z-index: 1!important;
		}

		&[data-layyer="stock"],
		&[data-layyer="card"] {
			position: absolute;
			opacity: 0;
			left: 0;
			z-index: -1;
		}
 	}
	&__title {
		font-size: rem(24);
		line-height: rem(36);
		font-weight: 700;
		margin-bottom: rem(16);

		&[data-move='back'] {
			cursor: pointer;

			&:hover {
				&:before {
					left: rem(0);
				}
			}
			&:before {
				content: "\f053";
				position: relative;
				top: rem(-2);
				left: rem(3);
				font-family: 'FontAwesome';
				font-size: rem(16);
				margin-right: rem(16);
				transition: 1s all;
			}
		}
	}
}

.state-list,
.stock-list{
	@include list-reset;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	&__item {
		cursor: pointer;
		font-size: rem(18);
		line-height: rem(27);
		// transition: 0.4s all;

		&:hover,
		&.active{
			@include gradient-color($clr-gradient);
		}
	}
	&__item + &__item {
		margin-top: rem(12);
	}
}

.stock-location {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	font-size: rem(14);
	line-height: rem(19);
	> * + * {
		margin-top: rem(8);
	}
	a {
		text-decoration: none;
		transition: none;
	}
	&__name {
		font-size: rem(24);
		line-height: rem(36);
		font-weight: 700;
		@include gradient-color($clr-gradient);
	}
	&__address {

	}
	&__manager {
		.label {
			color: #7A7E92;
		}
		.value {

		}
	}
	&__email {
		color: #1458EB !important;
	}
	&__phone {
		margin-top: rem(16);
		font-size: rem(18);
		line-height: rem(27);
		color: initial;
	}
}


/*========================================================
                      Responsive
=========================================================*/
.map-info {
	// ================
	// min-width: 1024 and max-width: 1366 - 1px
	@include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {

	}

	// max-width: 1023px
	@include below((map-get($grid-breakpoints, lg) - 1px)) {
		&__layyer {
			padding-top: rem(20);
			padding-left: rem(16);
			padding-right: rem(16);
		}
	}
}
