.btn-phone {
  width: rem(176);
  height: rem(36);
  font-size: rem(16);
  line-height: rem(21);
  padding: 0;
  box-shadow: none;
  &:hover {
    top: 0;
    box-shadow: none;
    -webkit-filter: brightness(1.15);
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem(36);
  }
}
