.catalog-send {
    padding-top: rem(80);
    padding-bottom: rem(80);
    &__title {
        margin: auto;
        text-align: center;
        font-weight: 400;
        line-height: rem(51);
        margin-bottom: rem(40);
        max-width: rem(820);
    }

    &__magazine {
        position: relative;
        width: 50%;
    }
    &__round {
        position: absolute;
        left: 50%;
        bottom: rem(50);
        transform: translateX(-50%);
        width: rem(128);
        height: rem(128);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;
        color: $white;
        font-size: rem(14);
        line-height: rem(21);
        time {
            display: block;
            font-weight: 700;
        }
    }
    .form__btn {
        margin-right: 0;
    }
}

/*========================================================
                      Responsive
=========================================================*/

.catalog-send {
    @include below(1920px) {
        &:before,
        &:after {
            display: none;
        }
    }
    // min-width: 1366
    @include above(map-get($grid-breakpoints, xl)) {
        padding-bottom: rem(330);
        &__magazine {
            position: absolute;
            left: rem(0);
            top: rem(-50);
            width: 65%;
        }
        &__form {
            position: relative;
            top: rem(90);
        }
    }
    // ================
    // min-width: 1024 and max-width: 1366 - 1px
    @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {
        &__round {
            bottom: 0
        }
    }

    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        padding-top: rem(64);
        padding-bottom: rem(64);
        &__title {
            font-size: rem(24);
            line-height: rem(36);
            max-width: 80%;
        }
    }

    // min-width: 768 and max-width: 1023 - 1px
    @include between(map-get($grid-breakpoints, md), (map-get($grid-breakpoints, lg) - 1px)) {
        padding-bottom: calc(67px + 30px + 128px / 2);
        &__title {
            margin-bottom: rem(100);
        }
        &__magazine {
            position: absolute;
            left: rem(-180);
            top: rem(-40);
            width: 75%;
        }
    }

    // max-width: 767px
    @include below((map-get($grid-breakpoints, md) - 1px)) {
        padding-top: rem(40);
        padding-bottom: rem(40);
        &__title {
            max-width: 100%;
        }
        &__magazine {
            position: absolute;
            width: 85%;
            top: 0;
            left: 50%;
            transform: translateX(-50%) !important;
        }
    }
    // max-width: 575px
    @include below((map-get($grid-breakpoints, sm) - 1px)) {
        &__title {
            margin: 0 auto;
            font-size: rem(18);
            line-height: rem(27);
        }
        &__round {
            display: none;
        }
        &__magazine {
            top: 40px;
            left: 50%;
            transform: translateX(-50%) !important;
        }
    }
}
