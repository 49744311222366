.swiperReviews {
  &-container {
    margin-top: rem(64);
  }

  &__nav-btn {
    cursor: pointer;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: rem(48);
    height: rem(48);
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease all;
    z-index: 10;
    opacity: 0.5;

    &:focus {
      outline: transparent
    }
    &:hover {
      opacity: 1;
    }

    &:before {
      position: relative;
      font-size: rem(24);
      color: currentColor;
      font-family: $fa;
      @include gradient-color($clr-gradient);
    }

    &--next {
      right: rem(24);

      &:before {
        content: "\f054";
        left: 1px;
      }
    }

    &--prev {
      left: rem(24);

      &:before {
        content: "\f053";
        right: 1px;
      }
    }

    &.swiper-button-disabled {
      opacity: 0.15;
    }
  }

  &__pagination {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: rem(76) auto 0;
    bottom: 0 !important;
    // transform: translateX(-50%);
  }

  &__pagination-bullet {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: rem(56);
    height: rem(56);
    border-radius: 50%;
    background: transparent;
    opacity: 0.3;
    transition: 0.4s all;
    span {
      overflow: hidden;
      width: rem(32);
      height: rem(32);
      background: $white;
      border-radius: 50%;
    }

    &:focus {
      outline: none;
    }

    &--active {
      opacity: 1;
      width: rem(56);
      height: rem(56);
      margin: 0 rem(32);
      background: $clr-gradient;

      span {
        width: rem(54);
        height: rem(54);
      }
    }
  }
}

.swiperReviews {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  transition-property: opacity;
  &__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
  }

  &__user {
    margin-top: rem(16);
    font-size: rem(24);
    line-height: rem(36);
    font-weight: 700;
  }

  &__round {
    &.round {
      position: relative;

    }

    .round {
      &__cnt {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(116);
        height: rem(116);
        border-radius: 50%;
        background: $white;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }

  &__content {
    display: flex;
    margin: rem(40) auto auto;
    align-items: flex-start;
  }

  &__blockquote {
    margin: 0;
    position: relative;
    padding: rem(64) rem(32) rem(32);
    width: rem(600);
    background: $white;
    color: $clr-main;
    box-shadow: 0px 26px 26px rgba(6, 26, 70, 0.12), 0px 0px 1px rgba(6, 26, 70, 0.1);
    border-radius: rem(10);
    z-index: 1;
  }
}

.blockquote {
  &__title {
    font-weight: 700;
    line-height: 1.3;
    font-size: rem(24);
  }

  &__text {
    margin-top: rem(15);
    line-height: rem(19);
    font-size: rem(14);
  }

  &__icon {
    position: absolute;
    width: rem(58);
    height: rem(40);
    top: rem(-20);
    left: rem(32);
    fill: url('#blockquote-gradient');
  }
}

/*========================================================
                      Responsive
=========================================================*/

.swiperReviews {
  // ================
  // min-width: 1024 and max-width: 1366 - 1px
  @include between(map-get($grid-breakpoints, lg), (map-get($grid-breakpoints, xl) - 1px)) {

  }
  // max-width: 1023px
  @include below((map-get($grid-breakpoints, lg) - 1px)) {
    &__blockquote {
      width: rem(528);
    }
    .blockquote {
      &__icon {
        left: rem(32);
      }
    }
  }

  // min-width: 768px
  @include above((map-get($grid-breakpoints, md))) {
    > * {
      opacity: 0;
      transform: translate3d(0, 10%, 0);
      transition: all .6s;
    }
    &.swiper-slide-active {
      > * {
        opacity: 1;
        transform: none;

        @for $i from 0 to 15 {
          &:nth-child(#{$i + 1}) {
            transition-delay: $i * 0.1 + 0.3s;
          }
        }
      }

      .swiperReviews__content {

      }
    }
  }

  // max-width: 767px
  @include below((map-get($grid-breakpoints, md) - 1px)) {
    width: 100% !important;
    &__nav-btn {
      display: none;
    }
    &__pagination {
      margin: rem(32) auto 0;
    }

    &-container {
      margin-top: rem(32);
    }
    &__user {
      margin-top: rem(8);
      font-size: rem(18);
      line-height: rem(27);
    }
    &__content {
      justify-content: center;
      margin-top: rem(44);
    }
    &__blockquote {
      margin: 0;
      position: relative;
      padding: rem(56) rem(16) rem(40);
      width: calc(100% - 40px);
      background: $white;
      color: $clr-main;
      box-shadow: 0px 26px 26px rgba(6, 26, 70, 0.12), 0px 0px 1px rgba(6, 26, 70, 0.1);
      border-radius: rem(10);
      z-index: 1;
    }
    .blockquote {
      &__icon {
        left: rem(16);
      }
    }
  }
}