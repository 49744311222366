.swiperWorks {
    &-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    &-wrapper {
        position: relative;
        width: rem(800);
        height: rem(450);
    }
    &-hidden {
        position: absolute;
        width: rem(800);
        height: rem(450);
        z-index: 10;
        top: 0;
        left: calc(50% + 8px);
        transform: translateX(-50%);
    }
}

.swiperWorks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: .15;

    &__active {
        opacity: 1;
    }

    &__img{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(800);
        height: rem(450);
        overflow: hidden;
        border-radius: 4px;
    }

    &__nav-btn {
        cursor: pointer;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $clr-gradient;
        color: $white;
        width: rem(48);
        height: rem(48);
        background: $clr-gradient;
        top: calc(50%);
        transform: translateY(-50%);
        transition: filter 0.3s;
        z-index: 10;
        &:hover {
            -webkit-filter: brightness(1.15);
        }
        &:focus {
            outline: transparent
        }

        &:before {
            position: relative;
            font-size: 16px;
            color: currentColor;
            font-family: $fa;
        }

        &:hover {
            filter: brightness(110%);
        }
        &--next {
            right: calc(50% - 424px);
            &:before {
                content: "\f054";
                left: 1px;
            }
        }
        &--prev {
            left: calc(50% - 424px);
            &:before {
                content: "\f053";
                right: 1px;
            }
        }

        &.swiper-button-disabled {
            opacity: 0.15;
        }
    }

    &__pagination {
        width: 100%;
        margin: rem(24) auto 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    &__pagination-bullet {
        cursor: pointer;
        display: inline-flex;
        width: rem(35);
        height: rem(24);
        border-radius: 2px;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.3;
        &:focus {
            outline: none ;
        }
        &--active {
            opacity: 1;
        }
    }
}

/*========================================================
                      Responsive
=========================================================*/

.swiperWorks  {
    // max-width: 1023px
    @include below((map-get($grid-breakpoints, lg) - 1px)) {
        &-wrapper {
            width: rem(620);
            height: rem(350);
        }
        &-hidden {
            width: rem(620);
            height: rem(350);
        }

        &__img{
            width: rem(620);
            height: rem(350);
        }

        &__nav-btn {
            &--next {
                right: calc(50% - 334px);
            }
            &--prev {
                left: calc(50% - 334px);
            }

            &.swiper-button-disabled {
                opacity: 0.15;
            }
        }

        &__pagination {
            padding: 0 !important;
            max-width: rem(620);
        }
        &__pagination-bullet {
            width: rem(27);
            height: rem(19);
        }
    }
}

.swiperWorks  {
    // max-width: 767px
    @include below((map-get($grid-breakpoints, md) - 1px)) {
        &-wrapper {
            height: auto;
        }
        &__img{
            width: initial;
            height: initial;
            max-width: calc(100% - 64px);
        }

        &-hidden {
            display: none;
        }

        &__nav-btn {
            width: 40px;
            height: 40px;
            &--next {
                right: 8px;
            }
            &--prev {
                left: 8px;
            }
        }

        &__pagination {
            pointer-events: none;
            max-width: calc(100% - 32px);
        }
    }
    // max-width: 575px
    @include below((map-get($grid-breakpoints, sm) - 1px)) {
        &__pagination {
            display: none;
        }
    }
}
