//.btn {
//  cursor: pointer;
//  position: relative;
//  text-decoration: none;
//  display: inline-flex;
//  align-items: center;
//  justify-content: center;
//  text-transform: uppercase;
//  width: 240px;
//  height: 39px;
//  border-radius: 42px;
//  border: none;
//  outline: none;
//  color: $white;
//  background: $clr-gradient;
//  box-shadow: 0px 3px 0px 0px #CC290B;
//  font-size: 14px;
//  transition: 0.3s ease;
//  &:active {
//    transform: translate(0px, 3px);
//    box-shadow: 0px 1px 0px 0px;
//  }
//  &:hover {
//
//  }
//}

.btn {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  width: 267px;
  height: 46px;
  color: $white;
  font-size: rem(14);
  font-weight: 400;
  border-radius: 25px;
  background: $clr-gradient;
  //background: linear-gradient(180deg, #FF9B0B, #F73F13);
  box-shadow: 0 5px 0 0 #CC290B, 0 8px 10px rgba(219, 105, 42, 0.3), 0 0 1px rgba(219, 105, 42, 0.2);
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.btn:hover {
  top: 2px;
  box-shadow: 0 3px 0 #CC290B, 0 8px 10px rgba(219, 105, 42, 0.3), 0 0 1px rgba(219, 105, 42, 0.2);
  -webkit-filter: brightness(1.15);
}
.btn:active {
  top: 4px;
  box-shadow: 0 1px 0 #CC290B, 0 8px 10px rgba(219, 105, 42, 0.3), 0 0 1px rgba(219, 105, 42, 0.2);
}