.partner-list {
  @include list-reset();
  display: flex;
  &__item {
    display: flex;
    width: rem(136);
    height: rem(58);
    align-items: center;
    justify-content: center;
  }
  &__img {

  }

  &__item +  &__item {
    margin-left: rem(2);
  }
}
