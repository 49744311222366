.brand {
  display: inline-flex;
  flex-shrink: 0;
  transition: 1.5s ease all;

  &__logo {
    display: inline-flex;
  }
}

