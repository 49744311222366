.catalog-filter {
  position: relative;
}

.catalog-filter-desc {
  display: none;
}
.catalog-filter-check {
  display: none;
  &__text {
    @include gradient-color($clr-gradient);
  }
}

.catalog-filter-check {
  cursor: pointer;
  position: relative;
  display: none;
  align-items: center;
  padding: 0 rem(10) 0 rem(24);
  height: rem(40);
  font-size: rem(14);
  background: rgba($clr-orange, 0.05);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: $clr-gradient;
  }
  &:after {
    content: '\f107';
    position: absolute;
    top: 50%;
    right: rem(16);
    font-size: rem(16);
    @include gradient-color($clr-gradient);
    font-family: $fa;
    transform: translateY(-50%);
  }
  &__text {
    @include gradient-color($clr-gradient);
  }
  &[data-open='true'] {
    &:after {
      content: '\f106';
    }
  }
}

.catalog-filter-list {
  //position: absolute;
  //top: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  // 	visibility: hidden;
  // 	opacity: 0;
  // 	height: 0;

  // 	@include c("is-visible") {
  // 		visibility: visible;
  // 		opacity: 1;
  // 		height: initial;
  // 		animation: fadeInDown .5s;
  // 	}

  &__item {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 rem(10) 0 rem(24);
    height: rem(40);
    font-size: rem(14);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: transparent;
    }
    &:hover {
      background: rgba($clr-orange, 0.05);
    }

    @include c("selected") {
      background: rgba($clr-orange, 0.05);
      &:before {
        background: $clr-gradient;
      }
      .catalog-filter-list {
        &__text {
          @include gradient-color($clr-gradient);
        }
      }
    }

  }
  &__text {
  }
}

/*========================================================
                      Responsive
=========================================================*/
// min-width: 1024px
@include above((map-get($grid-breakpoints, lg))) {
  .catalog-filter {
    display: block !important;
  }
}

// max-width: 1023px
@include below((map-get($grid-breakpoints, lg) - 1px)) {
  .catalog-filter-desc {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(8);
    color: $clr-main;
    font-size: rem(18);
    line-height: rem(27);

  }
  .catalog-filter-check {
    display: flex;
    margin-bottom: rem(32);
    z-index: 2;
  }
  .catalog-filter {
    display: none;
    position: absolute;
    top: rem(75);
    left: rem(12);
    right: rem(12);
    background: $white;
    border-bottom-left-radius: rem(4);
    border-bottom-right-radius: rem(4);
    z-index: 1;
    box-shadow: 0 3.11111px 4.14815px rgba(6, 26, 70, .1), 0 0 1.03704px rgba(6, 26, 70, .08);

    .catalog-filter-list {
      &__item {
        @include c("selected") {
          display: none;
        }
      }
    }
  }
}

// max-width: 575px
@include below((map-get($grid-breakpoints, sm) - 1px)) {
  .catalog-filter-check,
  .catalog-filter {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(16);
  }
  .catalog-filter-check {
    width: rem(232);
  }
  .catalog-filter {
    width: rem(230);
  }
}